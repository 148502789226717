<template>
  <div class="page-sidebar-inner slimscroll">
    <ul class="accordion-menu">
      <li v-for="(link, i) in links" :key="i">
        <router-link :to="link.to" class=""
          ><i class="material-icons-outlined">{{ link.icon }}</i
          >{{ link.title }}</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { icon: 'circle', title: 'Report Settings', to: '/reports'  },
        { icon: 'circle', title: 'Brand / Model Number Settings', to: '/brands'  },
        { icon: 'domain', title: 'Company Settings', to: '/companies'  },
      ]
    };
  },
};
</script>

<style>
</style>